@font-face {
  font-family: "ABCDiatype";
  src: url("/fonts/ABCDiatype/ABCDiatype-Light-Trial.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ABCDiatype";
  src: url("/fonts/ABCDiatype/ABCDiatype-Regular-Trial.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ABCDiatype";
  src: url("/fonts/ABCDiatype/ABCDiatype-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: medium;
  font-display: swap;
}

@font-face {
  font-family: "ABCDiatype";
  src: url("/fonts/ABCDiatype/ABCDiatype-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "RecklessNeue";
  src: url("/fonts/RecklessNeue/RecklessNeue-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RecklessNeue";
  src: url("/fonts/RecklessNeue/RecklessNeue-Medium.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rhode";
  src: url("/fonts/Rhode/RhodeMediumWide.woff2") format("woff2");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Pexel Grotesk";
  src: url("/fonts/PexelGrotesk/PexelGrotesk-Regular.otf") format("opentype");
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: "Decay";
  src: url("/drops/whitelist/Decay-White-Std.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Haas Grot Disp";
  src: url("/drops/smkls/NeueHaasGrotDisp-45Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Haas Grot Disp";
  src: url("/drops/smkls/NeueHaasGrotDisp-75Bold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arthemys Display";
  src: url("/drops/smkls/ArthemysDisplay-Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brozas";
  src: url("/drops/smkls/Brozas-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rigton Variable";
  src: url("/drops/smkls/Boyanurd_-_Rigton_Variable.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/drops/smkls/AktivGroteskCorp-Bold.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/drops/smkls/AktivGroteskCorp-Regular.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aktiv Grotesk";
  src: url("/drops/smkls/AktivGroteskCorp-Bold.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Visual";
  src: url("/fonts/Visual/Visual.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sainte Colombe";
  src: url("/fonts/SainteColombe/saintecolombeweb-light.woff") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apoc Revelations";
  src: url("/fonts/ApocRevelations/Apoc-Revelations-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apoc Revelations";
  src: url("/fonts/ApocRevelations/ApocRevelations-Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Apoc Revelations";
  src: url("/fonts/ApocRevelations/ApocRevelations-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nekst-Medium";
  src: url("/fonts/Nekst/Nekst-Medium.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nekst-Regular";
  src: url("/fonts/Nekst/Nekst Regular.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sinistre";
  src: url("/fonts/Sinistre/Collletttivo-Sinistre-Regular.otf") format("opentype");
  font-weight: 300;

  font-style: normal;
  font-display: swap;
}
